<template>
  <div class="mb-60 baojia-box liucheng-top rounded-lg">
    <v-card class="pa-4" flat>
      <empty :describe="$t('暂无消息~')" :imgSrc="emptysrc" v-if="newsdata.length==0"></empty>
      <div  v-else>

        <div class="text-left mb-10 col-F63605">
          <h4 class="mb-3">{{ $t("消息通知") }}</h4>
        </div>
        <div v-for="(item, index) in newsdata" :key="index">
          
          <div class="d-flex mb-4 mt-4">
            <div class="mr-4">
              <v-img width="52" src="@/assets/images/person/notice.png"></v-img>
            </div>
            <div style="width:100%">
              <div class="mb-2 d-flex align-center justify-space-between">
                <span class="text-body-1 font-weight-bold">{{
                  item.title
                }}</span>
                <v-badge dot type="error" size="10" v-if="item.read==1">
                  <span class="grey--text">{{item.create_time}}</span>
                </v-badge>
                <span v-else class="grey--text">{{item.create_time}}</span>
              </div>
              <div class="line2">
                {{item.content}}
              </div>
              <!-- <span style="" class="textred cursor float-right">{{
                $t("查看详情")
              }}</span> -->
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div
          v-if="pagearr.pagetotal > 0"
          class="text-center d-flex mt-8"
          align="center"
        >
          <v-row align="center" class="justify-center">
            <span>{{$t('共')}}10{{$t('页')}}</span>

            <v-pagination
              v-model="pagearr.page"
              :length="pagearr.pagetotal"
              total-visible="5"
              @input="getpagedata(1)"
            />
            <span class="mr-4 hidden-xs-only">{{ $t("条") }}</span>
            <span class="ml-6 hidden-xs-only">
              {{ $t("跳转至") }}
              <input
                v-model="pagearr.page"
                autocomplete="off"
                style="width: 80px"
                class="border in-border pl-2 pt-1 ml-2 mr-2 pb-1"
                type="number"
                @blur="getpagedata(1)"
              />
              {{ $t("页") }}
            </span>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
export default {
  components: { empty },
  data() {
    return {
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 15,
        pagetotal: 50,
        total: 0,
      },
      param:{},
      emptysrc:require('@/assets/images/other/zanwuxiaoxi@2x.png'),
      newsdata:[]
    };
  },
  mounted(){
     this.param["page"] = this.pagearr.page;
     this.param["page_size"] = this.pagearr.pagenum;
     this.param["read"]='1'
    this.getMsgs()
  },
  methods: {
    // 获取消息列表
    getMsgs(){
      this.$api.user.getUserMsg(this.param).then((res)=>{
        if(res.status=='success'){
          this.newsdata=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
           this.newsdata=[]
           this.pagearr.pagetotal = 0;
        }
      })
    },
    getpagedata() {},
  },
};
</script>
<style scoped>
/deep/ .v-pagination  .v-pagination__item{
  box-shadow: none;
  background: #F2F2F2;
}
/deep/ .v-pagination__navigation{
  box-shadow: none;
  background: #F2F2F2 !important;
}
</style>
<style >
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.textred {
  color: #f63605;
}
</style>